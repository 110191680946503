/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import clientApp from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import showToast from '../toast';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, setFetching, setLoggedInUser, setUserLoading } from '../../redux/features/user';
import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { setActivePrefix } from '../../redux/features/prefix';

const auth = clientApp.firebaseAuth;
const firestore = clientApp.firestore;
let prefixSubscriber;

const useAuth = ({redirect = false}) => {
   const dispatch = useDispatch();
   const { user, isLoading, isFetching } = useSelector(state => state.user);
   const [feature, setFeature] = useState({});
   const router  = useNavigate();

   const logout = async() => {
      try {
         dispatch(setUserLoading(true));
         await signOut(auth);
         dispatch(logoutUser());
         dispatch(setUserLoading(false));
         prefixSubscriber && prefixSubscriber();
         prefixSubscriber = null;
      } catch (e) {
         console.log(e);
      }
   }

   const getActivePrefix = () => {
      const q = query(
         collection(firestore, "prefix"),
         where('active','==', true),
      );
      if(prefixSubscriber) {
         prefixSubscriber && prefixSubscriber();
      }

      prefixSubscriber = onSnapshot(q, (querySnapshot) => {
         const list = [];
         querySnapshot.forEach((doc) => {
            const obj = doc.data();
            list.push({
               ...obj,
               createdAt: new Date(obj.createdAt).toString(),
               uid: doc.id
            });
         });
         if(list.length > 0) {
            dispatch(setActivePrefix(list[0]));
         }
      }, (err) => {
         console.log(err);
      });
   }

   const updateLastId = async(uid, lastId) => {
      try {
         let query = doc(firestore, 'prefix', uid);
         await setDoc(query, {
            lastId
         }, { merge: true });
      } catch (e) {
         console.log(e);
      }
   }

   const getFeatureFlag = async() => {
      try {
         let q = doc(firestore, 'feature', 'prod');
         onSnapshot(q, (snap) => {
            setFeature(snap.data());
         });
      } catch (err) {
         console.log(err);
      }
   }

   useEffect(() => {
      const authStateChanged = async (authState) => {
         if (authState) {
            const user = auth.currentUser;
            const result = await user.getIdTokenResult();

            user['role'] = result.claims?.role;
            user['isApprovedByAdmin'] = result.claims?.isApprovedByAdmin;

            dispatch(setFetching(false));
            dispatch(setLoggedInUser(user));
            dispatch(setUserLoading(false))
            getActivePrefix();
            getFeatureFlag();
            return;
         } else {
            dispatch(setLoggedInUser(null));
            dispatch(setUserLoading(false))
            redirect && router('/login')
         }
      };
      const unsubscribe = onAuthStateChanged(auth, authStateChanged);

      

      return () =>{
         prefixSubscriber && prefixSubscriber();
         prefixSubscriber = null;
         unsubscribe();
      };
   }, []);

   return {
      user,
      isLoading,
      logout,
      showToast,
      isFetching,
      updateLastId,
      feature,
   };
}

export default useAuth;
