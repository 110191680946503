import { createSlice } from '@reduxjs/toolkit'
import { collection, query, getDocs, where } from "firebase/firestore";
import { COLLECTION, ROLES, STATUS_NAME } from '../../utils/constant';
import { getDateRange, GROUP_BY } from '../../utils/date';
import clientApp from '../../utils/firebase';

const firestore = clientApp.firestore;

const initialState = {
    reports: [],
	users: [],
	employees: [],
	pms: [],
	sales: [],
	admins: [],
	clients: [],
	lastFetched: null,
	isFetching: true,
	countByTaskStatus: {
		new: 0,
		estimated: 0,
		done: 0,
		billed: 0,
		approved: 0 
	}
}

export const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setActivePrefix: (state, action) => {
			state.prefix = action.payload;
		},
		setLastFetched: (state, action) => {
			state.lastFetched = action.payload;
		},
		setUserList: (state, action) => {
			state.employees = action.payload.employees;
			state.pms = action.payload.pms;
			state.clients = action.payload.clients;
			state.admins = action.payload.admins;
			state.sales = action.payload.sales;
			state.users = action.payload.users;
		},
		setReports: (state, action) => {
			state.reports = action.payload.reports;
			state.countByTaskStatus = action.payload.countByTaskStatus;
		},
		setFetching: (state, action) => {
			state.isFetching = action.payload;
		}
	}
})

// Action creators are generated for each case reducer function
export const {
	setActivePrefix,
	setUserList,
	setReports,
	setFetching
} = reportsSlice.actions;

const fetchClientTasks = async (filterBy, clients, isUserSelected = false) => {

	let q = query(
		collection(firestore, COLLECTION.LIST)
	);

	if (isUserSelected) {
		q = query(
			collection(firestore, COLLECTION.LIST),
			where('clientId', '==', clients[0].uid)
		);
	}

	const listSnanshot = await getDocs(q);
	const list = [];
	
	listSnanshot.forEach((doc) => {
		const data = doc.data();
		list.push({
			...data,
			uid: doc.id,
			createdAt: data.createdAt.toDate().toString()	
		});
	});
	
	const dateRange = getDateRange(filterBy, {});

	// TODOS
	const tasks = [];
	for (let i in list) {
		const uid = list[i].uid;

		let q = query(
			collection(firestore, COLLECTION.LIST, uid, COLLECTION.TODOS),
			where('createdAt', '>=', new Date(dateRange.startDate)),
			where('createdAt', '<=', new Date(dateRange.endDate)),
		);

		const listSnanshot = await getDocs(q);
		listSnanshot.forEach((doc) => {
			const data = doc.data();
			const clientInfo = clients.find((x) => x.uid === list[i].clientId);
			tasks.push({
				uid: doc.id,
				clientId: list[i].clientId,
				client: clientInfo,
				duration: data.duration || 0,
				complementaryDuration: data.complementaryDuration || 0,
				status: data.status,
				title: data.title,
				prefix: data.prefix || '',
				createdAt: data.createdAt.toDate().toString(),
				createdBy: data.createdBy,
				pm: clientInfo.pm,
				sales: clientInfo.sales,
				employees: data.employees,
			});
		}); 
	}
	return tasks;
}

// const fetchClientTasksV2 = async (filterBy, clients, isUserSelected = false) => {
	
// 	const dateRange = getDateRange(filterBy, {});

// 	// TODOS
// 	const tasks = [];

// 	let q = query(
// 		collection(firestore, COLLECTION.NEW_TODOS),
// 		where('createdAt', '>=', new Date(dateRange.startDate)),
// 		where('createdAt', '<=', new Date(dateRange.endDate)),
// 	);

// 	if (isUserSelected) {
// 		q = query(
// 			collection(firestore, COLLECTION.NEW_TODOS),
// 			where('clientId', '==', clients[0].uid),
// 			where('createdAt', '>=', new Date(dateRange.startDate)),
// 			where('createdAt', '<=', new Date(dateRange.endDate)),
// 		);
// 	}

// 	const listSnanshot = await getDocs(q);
// 	listSnanshot.forEach((doc) => {
// 		const data = doc.data();
// 		const clientInfo = clients.find((x) => x.uid === data.clientId);

// 		tasks.push({
// 			uid: doc.id,
// 			clientId: data.clientId,
// 			client: clientInfo,
// 			duration: data.duration || 0,
// 			status: data.status,
// 			title: data.title,
// 			prefix: data.prefix || '',
// 			createdAt: data.createdAt.toDate().toString(),
// 			createdBy: data.createdBy,
// 			pm: clientInfo.pm,
// 			sales: clientInfo.sales,
// 			employees: data.employees,
// 		});
// 	}); 
// 	// for (let i in list) {
// 	// 	const uid = list[i].uid;

// 	// 	let q = query(
// 	// 		collection(firestore, COLLECTION.LIST, uid, COLLECTION.TODOS),
// 	// 		where('createdAt', '>=', new Date(dateRange.startDate)),
// 	// 		where('createdAt', '<=', new Date(dateRange.endDate)),
// 	// 	);

// 	// 	const listSnanshot = await getDocs(q);
// 	// 	listSnanshot.forEach((doc) => {
// 	// 		const data = doc.data();
// 	// 		const clientInfo = clients.find((x) => x.uid === list[i].clientId);

// 	// 		tasks.push({
// 	// 			uid: doc.id,
// 	// 			clientId: list[i].clientId,
// 	// 			client: clientInfo,
// 	// 			duration: data.duration || 0,
// 	// 			status: data.status,
// 	// 			title: data.title,
// 	// 			prefix: data.prefix || '',
// 	// 			createdAt: data.createdAt.toDate().toString(),
// 	// 			createdBy: data.createdBy,
// 	// 			pm: clientInfo.pm,
// 	// 			sales: clientInfo.sales
// 	// 		});
// 	// 	}); 
// 	// }
// 	return tasks;
// }


const groupingReports = (groupBy, tasks, users, selectedUser, employeesList) => {
	const reports = [];
	const filteredTasks = [];
	if (groupBy === GROUP_BY.CLIENT) {
		for (let i in tasks) {
			const index = reports.findIndex((x) => x.uid === tasks[i].clientId);
			if (index === -1) {
				const user = users.find((x) => x.uid === tasks[i].clientId);
				reports.push({
					uid: tasks[i].clientId,
					duration: Number(tasks[i]?.duration || 0),
					name: user?.name,
					email: user?.email,
					complementary: Number(tasks[i].complementaryDuration || 0),
				})
			} else {
				reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0)
				reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
				
			}
			filteredTasks.push(tasks[i]);
		}
		return {reports, filteredTasks};
	} else if (groupBy === GROUP_BY.PM) {
		for (let i in tasks) {
			const pms = tasks[i].pm;

			for (let j in pms) {
				if (selectedUser !== 'all' && selectedUser !== pms[j]) {
					continue;
				}

				if (selectedUser !== 'all') {
					// Show grouping by client tasks.
					const index = reports.findIndex((x) => x.uid === tasks[i].clientId);

					if (index === -1) {
						const user = users.find((x) => x.uid === tasks[i].clientId);
						reports.push({
							uid: tasks[i].clientId,
							duration: Number(tasks[i]?.duration || 0),
							name: user?.name,
							email: user?.email,
							complementary: Number(tasks[i].complementaryDuration || 0),
						})
					} else {
						reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0);
						reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
					}
				} else {
					const index = reports.findIndex((x) => x.uid === pms[j]);

					if (index === -1) {
						const user = users.find((x) => x.uid === pms[j]);
						reports.push({
							uid: pms[j],
							duration: Number(tasks[i]?.duration || 0),
							name: user?.name,
							email: user?.email,
							ccomplementary: Number(tasks[i].complementaryDuration || 0),
						})
					} else {
						reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0);
						reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
					}
				}
				filteredTasks.push(tasks[i]);
			}
		}
		return {reports, filteredTasks};
	} else if (groupBy === GROUP_BY.SALES) {
		for (let i in tasks) {
			const sales = tasks[i].sales;

			for (let j in sales) {
				if (selectedUser !== 'all' && selectedUser !== sales[j]) {
					continue;
				}

				if (selectedUser !== 'all') {
					// Show grouping by client tasks.
					const index = reports.findIndex((x) => x.uid === tasks[i].clientId);

					if (index === -1) {
						const user = users.find((x) => x.uid === tasks[i].clientId);
						reports.push({
							uid: tasks[i].clientId,
							duration: Number(tasks[i]?.duration || 0),
							name: user?.name,
							email: user?.email,
							complementary: Number(tasks[i].complementaryDuration || 0),
						})
					} else {
						reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0);
						reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
					}
				} else {
					const index = reports.findIndex((x) => x.uid === sales[j]);

					if (index === -1) {
						const user = users.find((x) => x.uid === sales[j]);
						reports.push({
							uid: sales[j],
							duration: Number(tasks[i]?.duration || 0),
							name: user?.name,
							email: user?.email,
							complementary: Number(tasks[i].complementaryDuration || 0),
						})
					} else {
						reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0)
						reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
					}
				}

				filteredTasks.push(tasks[i]);
			}
		}
		return {reports, filteredTasks};
	} else if (groupBy === GROUP_BY.EMPLOYEE) {
		for (let i in tasks) {
			const employees = tasks[i].employees;

			for (let j in employees) {
				if (selectedUser !== 'all' && selectedUser !== employees[j]) {
					continue;
				}

				if (selectedUser !== 'all') {
					// Show grouping by client tasks.
					const index = reports.findIndex((x) => x.uid === employees[j]);
					if (index === -1) {
						const user = employeesList.find((x) => x.uid === employees[j]);
						reports.push({
							// uid: tasks[i].clientId,
							uid: employees[j],
							duration: Number(tasks[i]?.duration || 0),
							name: user?.name,
							email: user?.email,
							complementary: Number(tasks[i].complementaryDuration || 0),
						})
					} else {
						reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0)
						reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
					}
				} else {
					const index = reports.findIndex((x) => x.uid === employees[j]);

					if (index === -1) {
						const user = employeesList.find((x) => x.uid === employees[j]);
						reports.push({
							uid: employees[j],
							duration: Number(tasks[i]?.duration || 0),
							name: user?.name,
							email: user?.email,
							complementary: Number(tasks[i].complementaryDuration || 0),
						})
					} else {
						reports[index].duration = Number(reports[index].duration) + Number(tasks[i]?.duration || 0)
						reports[index].complementary = Number(reports[index].complementary) + Number(tasks[i]?.complementaryDuration || 0)
					}
				}

				filteredTasks.push(tasks[i]);
			}
		}
		return {reports, filteredTasks};
	} else {
		return {reports: [], filteredTasks: []};
	}
}

const groupingTaskByStatus = (tasks) => {
	// const new = tasks.filter((x) => x.status === STATUS_NAME.NEW).length;
	return {
		new: tasks.filter((x) => x.status === STATUS_NAME.NEW).length,
		estimated: tasks.filter((x) => x.status === STATUS_NAME.ESTIMATED).length,
		completed: tasks.filter((x) => x.status === STATUS_NAME.COMPLETED).length,
		billed: tasks.filter((x) => x.status === STATUS_NAME.BILLED).length,
		approved: tasks.filter((x) => x.status === STATUS_NAME.APPROVED).length,
		complementary: tasks.filter((x) => x.complementaryDuration && x.complementaryDuration > 0).length,
	}
}

export const fetchDataForReports = ({filterBy, groupBy, selectedUser}) => {
	return async (dispatch) => {
		/*
		 * 1. Fetch Users ( PM, Client, Sales, Admin, Accountant )
		 * 2. Fetch client project tasks.
		 */ 
		let users = [], clients = [], admins = [], pms = [], sales = [], employees = [];
		dispatch(setFetching(true));

		const querySnapshot = await getDocs(
			collection(firestore, COLLECTION.USER),
		);
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			let obj = {
				...data,
				uid: doc.id,
				createdAt: data.createdAt.toDate().toString(),
			};
			users.push(obj);

			if (obj.role === ROLES.CLIENT) {
				clients.push(obj);
			}
			if (obj.role === ROLES.PM) {
				pms.push(obj);
			}
			if (obj.role === ROLES.SALES) {
				sales.push(obj);
			}
			if (obj.role === ROLES.ADMIN) {
				admins.push(obj);
			}
		});

		const employeeQuerySnapshot = await getDocs(
			collection(firestore, COLLECTION.EMPLOYEE),
		);

		employeeQuerySnapshot.forEach((doc) => {
			const data = doc.data();
			employees.push({
				...data,
				uid: doc.id,
				createdAt: data.createdAt.toDate().toString(),
			}) 
		})

		dispatch(setUserList({
			employees,
			pms,
			clients,
			admins,
			sales,
			users,
		}));

		let isUserSelected = false;

		if (groupBy === GROUP_BY.CLIENT && selectedUser !== 'all') {
			const oneClient = clients.find((x) => x.uid === selectedUser);
			clients = [oneClient];
			isUserSelected = true;
		}
		
		const tasks = await fetchClientTasks(filterBy, clients, isUserSelected);

		// const tasks = await fetchClientTasksV2(filterBy, clients, isUserSelected);
		
		
		const { reports, filteredTasks } = groupingReports(groupBy, tasks, users, selectedUser, employees);
		
		const countByTaskStatus = groupingTaskByStatus(filteredTasks);

		dispatch(setReports({reports, countByTaskStatus }));
		dispatch(setFetching(false));
		return;
    }
}

export default reportsSlice.reducer