import { React } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
  MenuGroup,
  Container,
  Image,
  Text,
} from '@chakra-ui/react';
import {Link as ReachLink, useNavigate, useLocation } from 'react-router-dom';
import { GiHamburgerMenu  } from "react-icons/gi";
import {GrClose} from "react-icons/gr"
import { getMenuRoutes } from '../../router/constant';

const PageLinks = getMenuRoutes();

const NavLink = ({ children, onClose, link, user, location }) => {
  const hover = {
    textDecoration: 'none',
    bg: useColorModeValue('gray.200', 'gray.700'),
  };
  if (link.allowed.indexOf(user?.role) > -1) {
    return (
      <Link
        backgroundColor={location.pathname === link.pathName ? 'gray.200' : null}
        m={0}
        key={link.pathName}
        px={3}
        py={2}
        as={ReachLink}
        rounded={'md'}
        fontSize={'sm'}
        _hover={hover}
        _active={hover}
        onClick={onClose}
        to={link.pathName}>
        {children}
      </Link>
    )
  }
  return null;
};

export default function Header({ logout, user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useNavigate();
  const location = useLocation();
  const getName = () => {
    return `${user.displayName}`;
  }

  return (
    <>
      <Box px={3}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            icon={<Container>{isOpen ? <GrClose /> : <GiHamburgerMenu/> }</Container>}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box cursor={'pointer'} onClick={() =>router('/work')} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {process.env.REACT_APP_MODE  === 'PROD' && <Image src={require('../../assets/images/logo.jpg')} mr={2} h={'30px'} alt='Time sheet'/>}
              <Text>
                {process.env.REACT_APP_MODE  === 'DEV' ? 'TEST' : 'Hupp'}
              </Text>
            </Box>
            <HStack
              as={'nav'}
              spacing={2}
              display={{ base: 'none', md: 'flex' }}>
              {PageLinks.map((link, index) => (
                <NavLink location={location} key={index} user={user} link={link}>{link.title}</NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                {/* <Tooltip label={getName()}> */}
                  <Avatar
                    size={'sm'}
                    name={getName()}
                  />
                {/* </Tooltip> */}
              </MenuButton>
              <MenuList>
                <MenuGroup title={getName()}>
                  <MenuItem pointerEvents={'none'} fontSize={'sm'}>{user.email}</MenuItem>
                  <MenuItem fontSize={'sm'}>My Account</MenuItem>
                  
                    <Link to="/settings" _hover={{
                      textDecoration:'none'
                    }} textDecoration={'none'} as={ReachLink}>
                      <MenuItem fontSize={'sm'}>
                        Settings
                      </MenuItem>
                    </Link>
                  
                  <MenuItem fontSize={'sm'}  onClick={logout}>Logout</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={0}>
              {PageLinks.map((link, index) => (
                <NavLink location={location} onClose={onClose} user={user} link={link} key={index}>{link.title}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
