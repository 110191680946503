import { configureStore,  } from '@reduxjs/toolkit'
import clientReducer from './features/client';
import prefixReducer from './features/prefix';
import projectReducer from './features/project'
import userReducer from './features/user';
import reportReducer from './features/reports';
import employeeReducer from './features/employee';
import workReducer from './features/work';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['user/setUserLoading','user/setLoggedInUser','user/setUser'],
        // // // Ignore these field paths in all actions
        // ignoredActionPaths: ['user'],
        // // Ignore these paths in the state
        ignoredPaths: ['user'],
      },
    }),
  reducer: {
    project: projectReducer,
    user: userReducer,
    client: clientReducer,
    prefix: prefixReducer,
    report: reportReducer,
    employee: employeeReducer,
    work: workReducer,
  },
})