
import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { AiOutlineWarning } from 'react-icons/ai';

export default function UnderMaintenance() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'orange'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center">
          <AiOutlineWarning size={35} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Site Under Maintenance
      </Heading>
      <Text color={'gray.500'} fontSize={'20px'}>
        Please contact the <Text as={'span'} color={"primary.500"}>Hupp team</Text>.
      </Text>
    </Box>
  );
}
