import axios from 'axios';


const getHeader = (token) => {
    return {
        'x-auth-header': token
    }
}

const API = process.env.REACT_APP_API_URL;

const post = (url, body, token) => {
    return axios({
        method: 'POST',
        url: `${API}${url}`,
        headers: getHeader(token),
        data: body
    })
}

const Http = {
    post
}


export default Http;