export const ROLES = {
    ADMIN: 'admin',
    PM: 'pm',
    SALES: 'sales',
    ACCOUNTANT: 'accountant',
    CLIENT: 'client'
}

export const UI_FROM = {
    USER: 'user',
    CLIENT: 'client'
}

export const USER_ROLES = [
    ROLES.ADMIN,
    ROLES.PM,
    ROLES.SALES,
    ROLES.ACCOUNTANT,
    ROLES.CLIENT
];

export const getAllowedRoles = (from) => {
    const roles = [...USER_ROLES]
    if(from === UI_FROM.USER) {
        roles.splice(roles.indexOf(ROLES.CLIENT), 1);
        roles.splice(roles.indexOf(ROLES.ADMIN), 1);
        return roles;
    }
    return [ROLES.CLIENT];
}

export const isAllowedToFetchClient = (role) => {
    if([ROLES.ACCOUNTANT, ROLES.CLIENT, ROLES.PM].indexOf(role) > -1) {
        return false;
    }
    return true;
}

export const isAllowToApprovedTask = (role) => {
    return [ROLES.ADMIN, ROLES.CLIENT, ROLES.SALES].indexOf(role) > -1;
}

export const isAllowedToMarkAsComplete = (role) => {
    return [ROLES.ADMIN, ROLES.PM, ROLES.SALES].indexOf(role) > -1;
}

export const isAllowedToMarkAsBilled = (role) => {
    return [ROLES.ADMIN, ROLES.ACCOUNTANT].indexOf(role) > -1;
}

export const isAllowedToMarkAsComplementary = (role) => {
    return [ROLES.ADMIN].indexOf(role) > -1;
}

export const isAllowedToMarkAsArchived = (role) => {
    return [ROLES.ADMIN, ROLES.PM].indexOf(role) > -1;
}

export const isAllowedToDelete = (user, todo) => {
    return (user.uid === todo.createdBy || user.role === ROLES.ADMIN);
}

export const isAllowedToDeleteProject = (user, project) => {
    return (user.uid === project.createdBy || user.role === ROLES.ADMIN);
}

export const hasPrefixPermission = (user) => {
    return user.role === ROLES.ADMIN;
}

export const STATUS_NAME = {
    NEW: 'new',
    ESTIMATED: 'estimated',
    APPROVED: 'approved',
    DECLINED: 'declined',
    IN_PROGRESS: 'in progress',
    COMPLETED: 'completed',
    BILLED: 'billed',
    ARCHIVED: 'archived',
    COMPLEMENTARY: 'complementary',
}

export const DEFAULT_FILTER = [
    STATUS_NAME.NEW,
    STATUS_NAME.ESTIMATED,
    STATUS_NAME.APPROVED,
    STATUS_NAME.DECLINED,
    STATUS_NAME.COMPLETED,
]

export const TASK_STATUS = [
    {
        status: STATUS_NAME.NEW,
        color: '#ECC94B'
    },
    {
        status: STATUS_NAME.ESTIMATED,
        color: '#975A16'
    },
    {
        status: STATUS_NAME.APPROVED,
        color: '#48BB78'
    },
    {
        status: STATUS_NAME.DECLINED,
        color: '#ff0000'
    },
    {
        status: STATUS_NAME.IN_PROGRESS,
        color: '#B794F4'
    },
    {
        status: STATUS_NAME.COMPLETED,
        color: '#4299E1'
    },
    // {
    //     status: STATUS_NAME.COMPLEMENTARY,
    //     color: '#ffb0b0'
    // },
    // {
    //     status: STATUS_NAME.BILLED,
    //     color: '#ccffcc'
    // },
    {
        status: STATUS_NAME.ARCHIVED,
        color: '#83db6d'
    },
];

export const COLLECTION = {
    USER: 'user',
    EMPLOYEE: 'employee',
    LIST: 'list',
    TODOS: 'todos',
    NEW_TODOS: 'new-todos'
}