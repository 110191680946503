import { signInWithPopup, GoogleAuthProvider,signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import firebase from '../utils/firebase'
import Http from "./http";

const auth = firebase.firebaseAuth;

const getToken = () => {
    return auth.currentUser?.accessToken;
}

const login = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
}

const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // The signed-in user info.
        return result.user;
        // ...
    }).catch((error) => {
        // Handle Errors here.
        console.log(error);
        // The email of the user's account used.
        // The AuthCredential type that was used.
        throw error;
        // ...
    });
}

const forgotPassword = () => {

}

const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
}

const createUser = (body) => {
    return Http.post('/createUser', body, getToken());
}

const updateUser = (body) => {
    return Http.post('/updateUser', body, getToken());
}

const updateUserStatus = (body) => {
    return Http.post('/updateUserStatus', body, getToken());
}

const assignRoleToUser = (body) => {
    return Http.post('/assignRole', body, getToken());
} 
const taskAction = (body) => {
    return Http.post('/taskAction', body, getToken());
} 

const taskActionV2 = (body) => {
    return Http.post('/taskActionV2', body, getToken());
} 

export {
    login,
    forgotPassword,
    loginWithGoogle,
    resetPassword,
    createUser,
    updateUserStatus,
    assignRoleToUser,
    taskAction,
    updateUser,
    taskActionV2,
}