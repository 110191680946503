import { Box, Container } from '@chakra-ui/react';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from '../components/header';
import Loader from '../components/loader';
import NoAccess from '../components/NoAccess';
import useAuth from '../utils/hooks/useAuth';
import ROUTES from './constant';
import { useSelector } from 'react-redux';
import UnderMaintenance from '../components/UnderMaintenance';

const RoleWrapper = ({children, user, isFetching, route}) => {
    if(isFetching) {
        return null;
    }

    const checkPageAccess = () => {
        return route.allowed.indexOf(user?.role) > -1; 
    }

    if(user && user.isApprovedByAdmin && checkPageAccess()) {
        return (
            <>
                {children}
            </>
        )
    }
    return <NoAccess/>
    
}

const ProtectedRoute = ({ children, route, state }) => {
    const { user, isLoading, isFetching, logout, feature } = useAuth({ redirect: true});

    useEffect(() => {
        document.title = `${route.title} | Hupp Sheet`
    }, [route.title]);

    if (feature && feature?.underMaintenance) {
        return (
            <Box display={'flex'} h={'100vh'} w={'100%'} justifyContent={'center'} mt={'200px'}>
                <UnderMaintenance />
            </Box>
        )
    }
    return (
        <>
            <Loader isLoading={isLoading || state.isLoading} />
            {user && <Header logout={logout} user={user}/>}
            {!isFetching && <RoleWrapper user={user} isFetching={isFetching} route={route}>
                {user && children}
            </RoleWrapper>}
        </>
    )
}

const AuthRoute = ({ children, route, state }) => {
    const { isLoading } = useAuth({ redirect: false});

    useEffect(() => {
        document.title = `${route.title} | Hupp Sheet`
    }, [route.title]);

    return (
        <>
            <Loader isLoading={isLoading || state.isLoading} />
            <>
                {children}
            </>
        </>
    )
}

const Router = () => {
    const state = useSelector(state => state.user);

    return (
        <Container p={0} height={'100vh'} maxW={'100%'}>
            <BrowserRouter>
                <Suspense fallback={<Loader isLoading={true} />}>
                    <Routes>
                        {
                            ROUTES.map((route, index) => {
                                if(route.isPrivate) {
                                    return (
                                        <Route exec key={index} path={route.pathName} element={<ProtectedRoute state={state} route={route}><route.Component {...state} /></ProtectedRoute>} />
                                    )
                                }
                                return (
                                    <Route exec key={index} path={route.pathName} element={<AuthRoute state={state} route={route}><route.Component /></AuthRoute>} />
                                )
                            })
                        }
                        <Route exec path="/*" element={<Navigate to="/work" />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Container>
    )
}

export default Router;