import { Container, Spinner } from "@chakra-ui/react";

function Loader({
    isLoading,
    height = '100vh',
    bgColor = '#cce5e88a'
}) {
    if(isLoading) {
        return (
            <Container zIndex={11} color='primary.500' bgColor={bgColor} alignItems={'center'} maxW={'100%'} position={'absolute'} height={height} display={'flex'} justifyContent={'center'}>
                <Spinner size={'xl'}  thickness='4px'  emptyColor='gray.200'/>
           </Container>
        )
    }
    return null;
}

export default Loader;
