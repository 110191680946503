import React from 'react';
import {
  ChakraProvider,
  extendTheme
} from '@chakra-ui/react';
import Router from './router';
import { Provider } from 'react-redux'
import { store } from './redux/store';
import { createStandaloneToast } from '@chakra-ui/react';

const { ToastContainer } = createStandaloneToast()

const theme = extendTheme({
  baseStyle: {
    _focus: {
      boxShadow: 'none'
    }
  },
  colors: {
    primary: {
      50: '#e6f2f4',
      100:'#cce5e8',
      200: '#99ccd1',
      300: '#80bfc6',
      400: '#4da5af',
      500: "#017f8d",
      600: '#016671',
      700: '#014c55',
      800: '##014047',
      900: '##003338'
    },
    // secondary: {
    //   50: '#e6f2f4',
    //   100:'#cce5e8',
    //   200: '#99ccd1',
    //   300: '#80bfc6',
    //   400: '#4da5af',
    //   500: "#017f8d",
    //   600: '#016671',
    //   700: '#014c55',
    //   800: '##014047',
    //   900: '##003338'
    // }
  },
  
}); 

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Router />
        <ToastContainer />
      </ChakraProvider>
    </Provider>
    
  );
}

export default App;
