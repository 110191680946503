import { createStandaloneToast } from '@chakra-ui/react';
const { toast } = createStandaloneToast()

const showToast = ({
    title,
    description = null,
    status
}) => {
    toast({
        title,
        description,
        status,
        duration: 3000,
        isClosable: true,
        position: 'top'
    })
}

export default showToast;