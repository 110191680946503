import { createSlice } from '@reduxjs/toolkit'
import { collection, addDoc, getDocs, setDoc, doc } from "firebase/firestore";
import clientApp from '../../utils/firebase';
import showToast from '../../utils/toast';

const firestore = clientApp.firestore;

const initialState = {
    list: [],
    isCreating: false,
    isCreated: false,
    isFetching: false
}

export const employeeSlice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		setEmployees: (state, action) => {
			state.list = action.payload;
        },
        setCreating: (state, action) => {
            state.isCreating = action.payload;
        },
        setCreated: (state, action) => {
            state.isCreated = action.payload;
        },
        setFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        setEmployeeAsDeleted: (state, action) => {
            const list = [...state.list];
            const index = list.findIndex((x) => x.uid === action.payload.uid);
            if (index > -1) {
                list[index].isDeleted = true;
            }
            
            state.list = [...list];
        }
	}
})

// Action creators are generated for each case reducer function
export const {
    setEmployeeAsDeleted,
    setEmployees,
    setCreating,
    setCreated,
    setFetching
} = employeeSlice.actions;

export default employeeSlice.reducer;

export const addEmployee = (employee, user) => {
	return async(dispatch) => {
		try {
			dispatch(setCreating(true));
			const obj = {
                ...employee,
                createdBy: user?.uid,
                createdAt: new Date()
            };

			await addDoc(collection(firestore, "employee"), obj);
            
            showToast({
                title: 'Success',
                status: 'success',
                description: "Added successfully"
            });

            dispatch(setCreating(false));
            dispatch(setCreated(true));
            dispatch(getEmployees());
		} catch (e) {
			dispatch(setCreating(false));
			console.log(e);
		}
	}
}

export const getEmployees = () => {
	return async(dispatch) => {
        try {
            dispatch(setFetching(true));
            const querySnapshot = await getDocs(
                collection(firestore, "employee")
            );
            
            const employees = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                employees.push({
                    ...data,
                    uid: doc.id,
                    createdAt: data.createdAt.toDate().toString(),
                });
            });

            dispatch(setEmployees(employees));
            dispatch(setFetching(false));
		} catch (e) {
			console.log(e);
		}
	}
}

export const deleteEmployee = (uid) => {
    return async (dispatch) => {
        try {
		
            await setDoc(doc(firestore, 'employee', uid), {
                isDeleted: true
            }, {
                merge: true
            });

            dispatch(setEmployeeAsDeleted({ uid }));

            showToast({
                title: 'Success',
                status: 'success',
                description: "Deleted successfully"
            });

        
        } catch (e) {
            console.log(e)
        }
    }
}
