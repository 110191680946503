import dayjs from "dayjs";

export const convertIntoFormat = (sec, includeHM = true) => {
    if(!sec) {
        return '';
    }
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes

    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) { minutes = "0" + minutes; }
    
    if (!includeHM) {
        return `${hours}:${minutes}`;
    }
    
    if(Number(minutes) === 0) {
        return `${Number(hours)}h`
    }
    if(Number(hours) === 0) {
        return `${Number(minutes)}m`
    }

    return `${Number(hours)}h ${Number(minutes)}m`
}

export const DAY_FILTER = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This week',
    LAST_WEEK: 'Last week',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
    THIS_YEAR: 'This year',
    LAST_YEAR: 'Last year',
    CUSTOM: 'Custom'
}

export const DAY_FILTER_ARRAY = [
    DAY_FILTER.TODAY,
    DAY_FILTER.YESTERDAY,
    DAY_FILTER.THIS_WEEK,
    DAY_FILTER.LAST_WEEK,
    DAY_FILTER.THIS_MONTH,
    DAY_FILTER.LAST_MONTH,
    DAY_FILTER.THIS_YEAR,
    DAY_FILTER.LAST_YEAR,
    // DAY_FILTER.CUSTOM
]

export const GROUP_BY = {
    CLIENT: 'Client',
    SALES: 'Sales',
    PM: 'PM',
    EMPLOYEE: 'Employee'
}

export const GROUP_BY_ARRAY = [
    GROUP_BY.CLIENT,
    GROUP_BY.SALES,
    GROUP_BY.PM,
    GROUP_BY.EMPLOYEE,
]

export const getDateRange = (type, data) => {
    let startDate, endDate;
    switch (type) {
        case DAY_FILTER.TODAY: {
            startDate = dayjs().startOf('day').unix();
            endDate = dayjs().endOf('day').unix();
            break;
        }
        case DAY_FILTER.YESTERDAY: {
            startDate = dayjs().subtract(1, 'day').startOf('day').unix();
            endDate = dayjs().subtract(1, 'day').endOf('day').unix();
            break;
        }
        case DAY_FILTER.THIS_WEEK: {
            startDate = dayjs().startOf('week').unix();
            endDate = dayjs().endOf('day').unix();
            break;
        }
        case DAY_FILTER.LAST_WEEK: {
            startDate = dayjs().subtract(1, 'week').startOf('day').unix();
            endDate = dayjs().subtract(1, 'week').endOf('day').unix();
            break;
        }
        case DAY_FILTER.THIS_MONTH: {
            startDate = dayjs().startOf('month').unix();
            endDate = dayjs().endOf('day').unix();
            break;
        }
        case DAY_FILTER.LAST_MONTH: {
            startDate = dayjs().subtract(1, 'month').startOf('month').unix();
            endDate = dayjs().subtract(1, 'month').endOf('month').unix();
            break;
        }
        case DAY_FILTER.THIS_YEAR: {
            startDate = dayjs().startOf('year').unix();
            endDate = dayjs().endOf('day').unix();
            break;
        }
        case DAY_FILTER.LAST_YEAR: {
            startDate = dayjs().subtract(1, 'year').startOf('year').unix();
            endDate = dayjs().subtract(1, 'year').endOf('year').unix();
            break;
        }
        case DAY_FILTER.CUSTOM: {
            startDate = dayjs(data.startDate).startOf('day').unix();
            endDate = dayjs(data.endDate).endOf('day').unix();
            break;
        }
        default: {
            startDate = dayjs().startOf('day').unix();
            endDate = dayjs().endOf('day').unix();
            break;
        }
    }

    return {
        startDate: startDate * 1000,
        endDate: endDate * 1000
    }
}