import { collection, query, orderBy, getDocs, where } from "firebase/firestore";
import { ROLES } from "../utils/constant";
import clientApp from '../utils/firebase';

const firestore = clientApp.firestore;

export const getAllClients = async(role, userId) => {
    let q;
    if(role === ROLES.CLIENT) {
        return;
    } else if(role === ROLES.ADMIN || role === ROLES.ACCOUNTANT) {
        q = query(
            collection(firestore, "user"),
            where('role','==', ROLES.CLIENT),
            orderBy('createdAt','desc')
        )
    } else if(role === ROLES.SALES) {
        q = query(
            collection(firestore, "user"),
            where('role','==', ROLES.CLIENT),
            where('sales','array-contains', userId),
            orderBy('createdAt','desc')
        );
    } else if(role === ROLES.PM) {
        q = query(
            collection(firestore, "user"),
            where('pm','array-contains', userId),
            where('role','==', ROLES.CLIENT),
            orderBy('createdAt','desc')
        );
    }
    const querySnapshot = await getDocs(q);
    const users = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        users.push({
            ...data,
            uid: doc.id,
            createdAt: data.createdAt.toDate().toString(),
        });
    });
    return users;
}