import { createSlice } from '@reduxjs/toolkit'
import { resetClient } from './client';
import { resetProject } from './project';
const initialState = {
    user: null,
    isLoading: true,
	isFetching: true
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setLoggedInUser: (state, action) => {
			state.user = action.payload;
		},
        setUserLoading: (state, action) => {
            state.isLoading = action.payload;
        },
		setFetching: (state, action) => {
			state.isFetching = action.payload;
		},
		resetUser: (state) => {
			Object.assign(state, initialState);
		}
	}
})

// Action creators are generated for each case reducer function
export const { setLoggedInUser, setUserLoading, setFetching, resetUser } = userSlice.actions

export const logoutUser = () => {
	return async(dispatch) => {
		dispatch(resetUser());
		dispatch(resetClient());
		dispatch(resetProject());
		let taskListSubscription = window.taskListSubscription || {};
		for (let key in taskListSubscription) {
			if (taskListSubscription.hasOwnProperty(key)) {
				taskListSubscription[key]();
			}
		}
		taskListSubscription = {};
		window.taskListSubscription = {};
	}
}

export default userSlice.reducer