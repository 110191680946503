import { createSlice } from '@reduxjs/toolkit'
import { collection, query, getDocs, orderBy, onSnapshot, where } from "firebase/firestore";
import { DEFAULT_FILTER } from '../../utils/constant';
import clientApp from '../../utils/firebase';

const firestore = clientApp.firestore;

const initialState = {
    projects: [],
    tasks: [],
    isProjectLoading: true,
    isTodoLoading: true,
    users: []
}

export const todoSlice = createSlice({
	name: 'workv2',
	initialState,
	reducers: {
		setTodoLoading: (state, action) => {
            state.isTodoLoading = action.payload;
            state.projects = [];
            state.tasks = [];
        },
        setProjectLoading: (state, action) => {
            state.isProjectLoading = action.payload;
        },
        setProjects: (state, action) => {
            state.isProjectLoading = false;
            state.projects = action.payload;
        },
        setTasks: (state, action) => {
            state.isTodoLoading = false;
            state.tasks = action.payload;
        },
        setAllUsers: (state, action) => {
            state.users = action.payload;
        }
	}
})

// Action creators are generated for each case reducer function
export const {
    setTodoLoading,
    setProjectLoading,
    setProjects,
    setTasks,
    setAllUsers,
} = todoSlice.actions


const getClientIds = (clients) => {
    const ids = [];

    for (let i in clients) {
        ids.push(clients[i].uid);
    }
    return ids;
}

window.listenerProject = null;
window.listenerTask = null;


export const fetchTaskListings = (clients, selectedStatus) => {
    return (dispatch) => {
        dispatch(getAllUsers());
        dispatch(setTodoLoading(true));
        dispatch(setProjectLoading(true));
        try {
            const ids = getClientIds(clients);
            // TODOS LIST
            let q = query(
                collection(firestore, "new-todos"),
                where('status', 'in', DEFAULT_FILTER),
                orderBy('createdAt', 'asc'),
            );

            if (selectedStatus !== 'all') {
                q = query(
                    collection(firestore, "new-todos"),
                    where('status', 'in', selectedStatus),
                    orderBy('createdAt','asc')
                )
            }
            
            if (window.listenerTask) {
                window.listenerTask();
                window.listenerTask = null;
            }

            window.listenerTask = onSnapshot(q, (querySnapshot) => {
                const tasks = [];
                
                querySnapshot.forEach((doc) => {
                    let data = doc.data();
                    if (ids.indexOf(data.clientId) > -1) {
                        tasks.push({
                            ...data,
                            uid: doc.id,
                            createdAt: data.createdAt.toDate().toString(),
                            completedAt: data?.completedAt ? data?.completedAt.toDate().toString() : null,
                            estimatedAt: data?.estimatedAt ? data?.estimatedAt.toDate().toString() : null,
                            apprvedAt: data?.apprvedAt ? data?.apprvedAt.toDate().toString() : null,
                            declinedAt: data?.declinedAt ? data?.declinedAt.toDate().toString() : null,
                            updatedAt: data?.updatedAt ? data?.updatedAt.toDate().toString() : null,
                            billedAt: data?.billedAt ? data?.billedAt.toDate().toString() : null,
                            archivedAt: data?.archivedAt ? data?.archivedAt.toDate().toString() : null,
                        });
                    }
                });

                dispatch(setTasks(tasks));
            }, (err) => {
                console.log(err);
                dispatch(setTodoLoading(false));
            });


        } catch (err) {
            console.log(err);
            dispatch(setTodoLoading(false));
            dispatch(setProjectLoading(false));
        }
    } 
}

export const fetchTodos = (clients, selectedStatus) => {
    return (dispatch) => {
        try {
            if (!window.listenerProject) {
                dispatch(getAllUsers());
                dispatch(setTodoLoading(true));
                dispatch(setProjectLoading(true));
                
                const ids = getClientIds(clients);
            
                let listQuery = query(
                    collection(firestore, "list"),
                    orderBy('createdAt','desc')
                )
                
                window.listenerProject = onSnapshot(listQuery, (querySnapshot) => {
                    const projects = [];
                    
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data['createdAt'] = data.createdAt.toDate().toString();
                        if (ids.indexOf(data.clientId) > -1) {
                            data['uid'] = doc.id;
                            projects.push(data);
                        }
                    });
    
                    dispatch(setProjects(projects));
                    
                }, (err) => {
                    console.log(err);
                    dispatch(setProjectLoading(false));
                });
    
                // TODOS LIST
                dispatch(fetchTaskListings(clients, selectedStatus));
            }
            

        } catch (err) {
            console.log(err);
            dispatch(setTodoLoading(false));
            dispatch(setProjectLoading(false));
        }
    }
}

export const getAllUsers = () => {
	return async(dispatch) => {
		try {
			let q = query(
				collection(firestore, "user")
			);
		
			const querySnapshot = await getDocs(q)
			const users = [];
			querySnapshot.forEach((doc) => {
				const obj = doc.data();
				users.push({
					userId: doc.id,
					name: obj.name,
					email: obj.email,
					pm: obj.pm,
					sales: obj.sales,
					role: obj.role
				});
			});
	
			dispatch(setAllUsers(users));
		} catch (e) {
			console.log(e);
		}
	} 
}

export default todoSlice.reducer;