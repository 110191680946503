import { createSlice } from '@reduxjs/toolkit'
import { collection, query, addDoc, getDocs, where, onSnapshot, doc, setDoc, deleteDoc } from "firebase/firestore";
import { taskAction, taskActionV2 } from '../../services/firebase';
import { ROLES, STATUS_NAME } from '../../utils/constant';
import clientApp from '../../utils/firebase';
import showToast from '../../utils/toast';

const firestore = clientApp.firestore;

let projectListSubscribe = null;

const initialState = {
    projects: [],
    tasks: {},
    count: 0,
	isCreatingProject: false,
	isProjectCreated: false,
	isFetchingProject: true,
	selectedStatus: 'all',
	projectToHide: [],
	projectTotalEstimate: {},
	users: [],
	showOnlyBilled: false,
}

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setProjects: (state, action) => {
			state.projects = [...action.payload];
		},
		setProjectCreating: (state, action) => {
			state.isCreatingProject = action.payload;
		},
		setProjectCreated: (state, action) => {
			state.isProjectCreated = action.payload;
		},
		setProjectFetching: (state, action) => {
			state.isFetchingProject = action.payload;
		},
		resetProject: (state) => {
			Object.assign(state, initialState);
		},
		setSelectedStatus: (state, action) => {
			state.selectedStatus = action.payload;
		},
		setHideProjects: (state, action) => {
			state.projectToHide = [...state.projectToHide, action.payload];
		},
		clearHideProjects: (state) => {
			state.projectToHide = [];
		},
		setProjectTask: (state, action) => {
			state.tasks = {
				...state.tasks,
				[action.payload.projectId]: action.payload.tasks
			}
		},
		setAllUsers: (state, action) => {
			state.users = action.payload;
		},
		setShowOnlyBilled: (state, action) => {
			state.showOnlyBilled = action.payload;
		}
	}
})

// Action creators are generated for each case reducer function
export const { setShowOnlyBilled, setAllUsers, setProjectFetching, setProjects, setProjectTask, setHideProjects, clearHideProjects, setProjectCreating, setProjectCreated, resetProject, setSelectedStatus } = projectSlice.actions

const getMyClients = async(uid, role) => {
	if(role === 'client') {
		return [uid];
	}
	let q = query(
		collection(firestore, "user"),
		where('pm', 'array-contains', uid)
	);

	if(role === 'sales') {
		q = query(
			collection(firestore, "user"),
			where('sales','array-contains', uid),
			// where('createdBy', '==',uid)
		);
	
	}
	const querySnapshot = await getDocs(q)
	const users = [];
	querySnapshot.forEach((doc) => {
        users.push(doc.id);
    });
	return users;
}


export const fetchProjects = (uid, role) => {
  return async(dispatch) => {
    try {
		// const {user: {role, uid} } = store.getState().user;

		dispatch(setProjectFetching(true));
		const myClients = await getMyClients(uid, role);
		
		let q = query(
			collection(firestore, "list"),
			where('clientId', '==', uid)
			// where('role','in', ['client']),
			// orderBy('createdAt','desc')
		)
		if(role === ROLES.PM || role === ROLES.SALES) {
			if(myClients.length === 0) {
				dispatch(setProjects([]));
				dispatch(setProjectFetching(false));
				return;
			}
			
			q = query(
				collection(firestore, "list"),
				// where('clientId','array-contains-any', myClients),
				// orderBy('createdAt','desc')
			)
		};
		
		if(role === ROLES.ADMIN || role === ROLES.ACCOUNTANT) {
			q = query(
				collection(firestore, "list"),
				// orderBy('createdAt','desc')
			)
		};
		projectListSubscribe = onSnapshot(q, (querySnapshot) => {
			const list = [];
			const proejctIds = [];
			
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				if (myClients.indexOf(data.clientId) > -1 && (role === ROLES.PM || ROLES.SALES))  {
					list.push({
						...data,
						createdAt: data.createdAt.toDate().toString(),
						uid: doc.id
					});
					proejctIds.push(doc.id);
					return;
				}
				if (role === ROLES.ADMIN || role === ROLES.ACCOUNTANT) {
					list.push({
						...data,
						createdAt: data.createdAt.toDate().toString(),
						uid: doc.id
					});
					proejctIds.push(doc.id);
					return;
				}
				return;
				
			});
			
			dispatch(setProjects(list));
			dispatch(setProjectFetching(false));
		}, (err) => {
			console.log(err);
			dispatch(setProjectFetching(false));
		});

    } catch (e) {
      console.log(e);
    }
  }
}

export const addNewProject = (project, user, clientId) => {
	return async(dispatch) => {
		try {
			dispatch(setProjectCreating(true));
			const obj = {
                ...project,
				createdBy: user?.uid,
				clientId: clientId || user?.uid
            };

			await addDoc(collection(firestore, "list"), obj);
            
            showToast({
                title: 'Success',
                status: 'success',
                description: "Added successfully"
            });
			dispatch(setProjectCreating(false));
			dispatch(setProjectCreated(true));
		} catch (e) {
			dispatch(setProjectCreating(false));
			console.log(e);
		}
	}
}

export const updateTaskStatus = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data};
			delete obj.proejctId;
			delete obj.taskId;

			await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
				...obj
			}, {
				merge: true
			});

			taskAction({
                pId: data.projectId,
                tId: data.taskId,
                action: data.status
			});

		} catch (e) {
			console.log(e);
		}
	}
}

export const updateTaskStatusV2 = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data};
			delete obj.proejctId;
			delete obj.taskId;

			await setDoc(doc(firestore, 'new-todos', data.taskId), {
				...obj
			}, {
				merge: true
			});

			taskActionV2({
                pId: data.projectId,
                tId: data.taskId,
                action: data.status
			});

		} catch (e) {
			console.log(e);
		}
	}
}

export const markTaskAsBilled = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data};
			delete obj.proejctId;
			delete obj.taskId;

			await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
				...obj
			}, {
				merge: true
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const markTaskAsBilledV2 = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data};
			delete obj.proejctId;
			delete obj.taskId;

			await setDoc(doc(firestore, 'new-todos', data.taskId), {
				...obj
			}, {
				merge: true
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const markTaskAsComplementary = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data};
			delete obj.proejctId;
			delete obj.taskId;

			await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
				...obj
			}, {
				merge: true
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const markTaskAsComplementaryV2 = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data};
			delete obj.proejctId;
			delete obj.taskId;

			await setDoc(doc(firestore, 'new-todos', data.taskId), {
				...obj
			}, {
				merge: true
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const setTimeIntoTask = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data, status: STATUS_NAME.ESTIMATED};
			delete obj.proejctId;
			delete obj.taskId;
			
			await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
				...obj,
				status: STATUS_NAME.ESTIMATED
			}, {
				merge: true
			});

			taskAction({
                pId: data.projectId,
                tId: data.taskId,
                action: STATUS_NAME.ESTIMATED
			});
			
		} catch (e) {
			console.log(e);
		}
	}
}

export const setTimeIntoTaskV2 = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data, status: STATUS_NAME.ESTIMATED};
			delete obj.proejctId;
			delete obj.taskId;
			
			await setDoc(doc(firestore, 'new-todos', data.taskId), {
				...obj,
				status: STATUS_NAME.ESTIMATED
			}, {
				merge: true
			});

			taskActionV2({
                pId: data.projectId,
                tId: data.taskId,
                action: STATUS_NAME.ESTIMATED
			});
			
		} catch (e) {
			console.log(e);
		}
	}
}


export const setComplementartTime = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data };
			delete obj.proejctId;
			delete obj.taskId;
			
			await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
				...obj
			}, {
				merge: true
			});

			// taskAction({
            //     pId: data.projectId,
            //     tId: data.taskId,
			// });
			
		} catch (e) {
			console.log(e);
		}
	}
}

export const setComplementartTimeV2 = (data) => {
	return async(dispatch) => {
		try {
			let obj = {...data };
			delete obj.proejctId;
			delete obj.taskId;
			
			await setDoc(doc(firestore, 'new-todos', data.taskId), {
				...obj
			}, {
				merge: true
			});

			// taskActionV2({
            //     pId: data.projectId,
            //     tId: data.taskId
			// });
			
		} catch (e) {
			console.log(e);
		}
	}
}

export const updateTaskInfo= async(data) => {
	try {
		await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
			title: data.title,
			description: data.description,
			updatedBy: data.updatedBy,
            updatedAt: data.updatedAt
		}, {
			merge: true
		});
		showToast({
			title:'Success',
			status: 'success',
			description: "Updated successfully"
		});
	} catch (e) {
		console.log(e)
	}
}

export const updateTaskInfoV2 = async(data) => {
	try {
		await setDoc(doc(firestore, 'new-todos', data.taskId), {
			title: data.title,
			description: data.description,
			updatedBy: data.updatedBy,
            updatedAt: data.updatedAt
		}, {
			merge: true
		});
		showToast({
			title:'Success',
			status: 'success',
			description: "Updated successfully"
		});
	} catch (e) {
		console.log(e)
	}
}

export const deleteTask = async(data) => {
	try {
		await deleteDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId));
		showToast({
			title:'Success',
			status: 'success',
			description: "Deleted successfully"
		});
	} catch (e) {
		console.log(e)
	}
}

export const deleteTaskV2 = async(data) => {
	try {
		await deleteDoc(doc(firestore, 'new-todos', data.taskId));
		showToast({
			title:'Success',
			status: 'success',
			description: "Deleted successfully"
		});
	} catch (e) {
		console.log(e)
	}
}

export const deleteProject = async(data) => {
	try {
		await deleteDoc(doc(firestore, 'list', data.projectId));
		showToast({
			title:'Success',
			status: 'success',
			description: "Deleted successfully"
		});
	} catch (e) {
		console.log(e)
	}
}

export const deleteProjectV2 = async(data) => {
	try {
		await deleteDoc(doc(firestore, 'list', data.projectId));
		showToast({
			title:'Success',
			status: 'success',
			description: "Deleted successfully"
		});
	} catch (e) {
		console.log(e)
	}
}

export const assignEmployeeToTask = async (data) => {
	try {
		await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
			employees: data.employees,
			updatedBy: data.updatedBy,
			updatedAt: data.updatedAt
		}, {
			merge: true
		});
		showToast({
			title:'Success',
			status: 'success',
			description: "Updated successfully"
		});
	} catch (error) {
		console.log(error);
	}
}

export const assignEmployeeToTaskV2 = async (data) => {
	try {
		await setDoc(doc(firestore, 'new-todos', data.taskId), {
			employees: data.employees,
			updatedBy: data.updatedBy,
			updatedAt: data.updatedAt
		}, {
			merge: true
		});
		showToast({
			title:'Success',
			status: 'success',
			description: "Updated successfully"
		});
	} catch (error) {
		console.log(error);
	}
}

export const assignCordinatorToTask = async (data) => {
	try {
		await setDoc(doc(firestore, 'list', data.projectId, 'todos', data.taskId), {
			cordinators: data.cordinators,
			updatedBy: data.updatedBy,
			updatedAt: data.updatedAt
		}, {
			merge: true
		});
		showToast({
			title:'Success',
			status: 'success',
			description: "Updated successfully"
		});
	} catch (error) {
		console.log(error);
	}
}

export const assignCordinatorToTaskV2 = async (data) => {
	try {
		await setDoc(doc(firestore, 'new-todos', data.taskId), {
			cordinators: data.cordinators,
			updatedBy: data.updatedBy,
			updatedAt: data.updatedAt
		}, {
			merge: true
		});
		showToast({
			title:'Success',
			status: 'success',
			description: "Updated successfully"
		});
	} catch (error) {
		console.log(error);
	}
}

export const getAllUsers = () => {
	return async(dispatch) => {
		try {
			let q = query(
				collection(firestore, "user")
			);
		
			const querySnapshot = await getDocs(q)
			const users = [];
			querySnapshot.forEach((doc) => {
				const obj = doc.data();
				users.push({
					userId: doc.id,
					name: obj.name,
					email: obj.email,
					pm: obj.pm,
					sales: obj.sales,
					role: obj.role
				});
			});
	
			dispatch(setAllUsers(users));
		} catch (e) {
			console.log(e);
		}
	} 
}

export const removeSubscribe = () => {
	projectListSubscribe && projectListSubscribe();
	projectListSubscribe = null;
}

export default projectSlice.reducer