import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import * as serviceWorker from './serviceWorker';
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en);

// ReactDOM.render(
//   <StrictMode>
//     <ColorModeScript />
//     <App />
//   </StrictMode>,
//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));

if(process.env.REACT_APP_MODE === 'PROD') {
  root.render(
    <StrictMode>
      <ColorModeScript />
      <App />
    </StrictMode>
  );
} else {
  root.render(
    <>
      <ColorModeScript />
      <App />
    </>
  );
}



// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
