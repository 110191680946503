import React from 'react';
import { ROLES } from '../utils/constant';

const ROUTES = [
    {
        title: 'Login',
        pathName: "/login",
        isPrivate: false,
        Component: React.lazy(() => import('../pages/login'))
    },
    {
        title: "Forgot Password",
        pathName: "/forgot-password",
        isPrivate: false,
        Component: React.lazy(() => import('../pages/forgot-password'))
    },
    {
        title: 'Work',
        pathName: "/work",
        isPrivate: true,
        Component: React.lazy(() => import('../pages/work')),
        isMenu: true,
        allowed: [ROLES.ADMIN, ROLES.SALES,ROLES.PM, 'super-admin', ROLES.CLIENT, ROLES.ACCOUNTANT]
    },
    // {
    //     title: 'Work V2',
    //     pathName: "/work-v2",
    //     isPrivate: true,
    //     Component: React.lazy(() => import('../pages/workv2')),
    //     isMenu: true,
    //     allowed: [ROLES.ADMIN]
    // },
    {
        title: "Client",
        pathName: "/client",
        isPrivate: true,
        Component: React.lazy(() => import('../pages/clients')),
        isMenu: true,
        allowed: [ROLES.ADMIN, ROLES.SALES, 'super-admin']
    },
    {
        title: 'Users',
        pathName: "/users",
        isPrivate: true,
        Component: React.lazy(() => import('../pages/users')),
        isMenu: true,
        allowed: [ROLES.ADMIN, 'super-admin']
    },
    {
        title: 'Pending Users',
        pathName: "/pending-users",
        isPrivate: true,
        Component: React.lazy(() => import('../pages/pending-users')),
        isMenu: true,
        allowed: [ROLES.ADMIN, 'super-admin']
    },
    {
        title: 'Employee',
        pathName: "/employee",
        isPrivate: true,
        Component: React.lazy(() => import('../pages/employee')),
        isMenu: true,
        allowed: [ROLES.ADMIN, 'super-admin']
    },
    {
        title: 'Setting',
        pathName: "/settings",
        isPrivate: true,
        Component: React.lazy(() => import('../pages/settings')),
        isMenu: false,
        allowed: [ROLES.ADMIN, 'super-admin', ROLES.SALES]
    },
    {
        title: 'Reports',
        pathName: "/reports",
        isPrivate: true,
        isMenu: true,
        allowed: [ROLES.ADMIN],
        Component: React.lazy(() => import('../pages/reports'))
    },
    {
        title: 'FAQ',
        pathName: "/faq",
        isPrivate: true,
        isMenu: true,
        allowed: [ROLES.ADMIN, ROLES.SALES,ROLES.PM, 'super-admin'],
        Component: React.lazy(() => import('../pages/faq'))
    },
]

export const getMenuRoutes = () => {
    const menus = [];
    ROUTES.filter((x) => {
        if(x.isMenu) {
            menus.push({
                title: x.title,
                pathName: x.pathName,
                allowed: x.allowed,
                isPrivate: x.isPrivate
            });
        }
        return false;
    })
    return menus;
}

export default ROUTES;