import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    prefix: null
}

export const prefixSlice = createSlice({
	name: 'prefix',
	initialState,
	reducers: {
		setActivePrefix: (state, action) => {
			state.prefix = action.payload;
		}
	}
})

// Action creators are generated for each case reducer function
export const { setActivePrefix } = prefixSlice.actions;

export default prefixSlice.reducer