import { createSlice } from '@reduxjs/toolkit'
import { getAllClients } from '../../services/client';

const initialState = {
    list: [],
    selectedClient: 'all',
    isClientLoaded: false,
    lastLoaded: null
}

export const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setClientList: (state, action) => {
            state.list = action.payload;
            state.isClientLoaded = true;
		},
        setSelectedClient: (state, action) => {
            state.selectedClient = action.payload;
        },
        resetClient: (state) => {
            Object.assign(state, initialState);
        }
	}
})

// Action creators are generated for each case reducer function
export const { setClientList, setSelectedClient, resetClient } = clientSlice.actions

export const getClients = (role, userId) => {
    return async(dispatch) => {
        try {
            if(role === 'client') {
                return;
            }
            const clients = await getAllClients(role, userId);
            dispatch(setClientList(clients));
        } catch (e) {
            console.log(e);
        }
    }
}

export default clientSlice.reducer