import {initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize firebase app.
const firebaseApp  = initializeApp(clientCredentials);
const firebaseAuth = getAuth(firebaseApp);
const firestore = getFirestore();
const firebase = {
  firebaseApp,
  firebaseAuth,
  firestore
}

export default firebase;
